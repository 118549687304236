
    .steps-wrapper {
        margin-top: 34px;
    }
    .education-wrapper {
        padding: 30px 30px 100px 30px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        /deep/ .el-scrollbar__view {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        .education-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .el-breadcrumb {
                line-height: 40px;
            }
        }
        .create-content {
            margin: 60px 0 30px;
            height: calc(100% - 56px);
            .handel-keyword-btn {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .btn-red-key {
                    color: #EB6547;
                    border-color: #EB6547;
                    padding: 7px 30px;
                    &:focus, &:hover {
                        color: #f78989;
                        border-color: #f78989;
                        background-color: #fff;
                    }
                }
                .btn-blue-key {
                    color: #1122D8;
                    border-color: #1122D8;
                    padding: 7px 29px;
                    &:focus, &:hover {
                        color: #747eea;
                        border-color: #747eea;
                        background-color: #fff;
                    }
                }
            }
            .success-content {
                margin-top: 155px;
                text-align: center;
                .iconfont {
                    color: #1FD18C;
                    font-size: 52px;
                }
                .text {
                    font-size: 24px;
                    margin: 20px 0 0;
                    font-weight: bold;
                }
            }
        }
    }
    .el-link + .el-link {
        margin-left: 10px;
    }
    .bottom-btn {
        text-align: center;
        z-index: 999999;
        position: absolute;
        bottom: 30px;
        left: 50%;
        margin-left: -95px;
    }
